import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/base/services';
import { credentialsKey } from '@app/shared/constants';
import { __ } from '@app/shared/functions/object.functions';
import { ApplicationUser } from '@app/shared/models/classes/ApplicationUser';
import { IdentityToken } from '@app/shared/models/classes/IdentityToken';
import { Organisation } from '@app/shared/models/classes/organisations/Organisation';
import { Role } from '@app/shared/models/classes/Role';
import { Ticket } from '@app/shared/models/classes/Ticket';
import { OrganisationsService } from '@app/shared/services/organisations.service';
import { UsersService } from '@app/shared/services/users.service';
import { environment } from '@env/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import moment from 'moment';
import { firstValueFrom, Observable, of, Subject, zip } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { HttpCacheService } from '../http/http-cache.service';
import { authCodeFlowConfig } from './oauth-config';

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService extends BaseService {

  // -----------------------------------------------------------------------------------------------------
  // @ READ ONLY VARIABLES
  // -----------------------------------------------------------------------------------------------------

  readonly clientId = 'siudfho2349!ihasd8gi3$ihsdo';

  readonly clientSecret = 'as9dzho2=gu8aishd8!89h3o';

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private _credentials: IdentityToken | null;

  private _credentials$: Subject<IdentityToken> = new Subject<IdentityToken>();

  // tslint:disable-next-line:member-ordering
  credentials$: Observable<IdentityToken> = this._credentials$.asObservable();

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private httpClient: HttpClient,
    private httpCacheService: HttpCacheService,
    private organisationsService: OrganisationsService,
    public oAuthService: OAuthService,
    private usersService: UsersService
  ) {
    super();

    const savedCredentials = localStorage.getItem(credentialsKey); // sessionStorage.getItem(credentialsKey) ||

    if (!__.IsNullOrUndefined(savedCredentials)) {
      this._credentials = JSON.parse(savedCredentials);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  /**
   * Authenticates the user.
   * @param body The login parameters.
   * @param remember Whether the user should stay logged in after closing the session
   * @return The user credentials.
   */
  login(body: AccessTokenRequestBody, remember?: boolean): Observable<ApplicationUser> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', `application/x-www-form-urlencoded`);

    return this.httpClient
      .disableAccessToken()
      .enableBasicAuthorization()
      .disableApiPrefix()
      .skipErrorHandler()
      .post<any>(
        `${environment.idServerUrl}connect/token`,
        `grant_type=password&username=${encodeURIComponent(body.email)}&password=${encodeURIComponent(body.password)}`,
        { headers }
      )
      .pipe(
        mergeMap((result: Ticket) => {
          const identityToken = {
            access_token: result.access_token,
            expires: moment().add('s', result.expires_in).toISOString(),
            refresh_token: result.refresh_token,
            user: null as ApplicationUser,
            organisation: null as Organisation
          };

          this.setCredentials(identityToken, true);
          const decodedToken = this.decodeToken(identityToken.access_token);

          return zip(
            this.usersService.getCurrentUser(),
            this.organisationsService.getById(decodedToken.organisationId)
          ).pipe(
            map((result: [ApplicationUser, Organisation]) => {
              identityToken.user = result[0];
              identityToken.organisation = result[1];

              this.setCredentials(identityToken, true);

              return identityToken.user;
            })
          );
        })
      );
  }

  get user(): ApplicationUser {
    return this._credentials?.user;
  }

  get ticket(): any {
    return {
      accessToken: this._credentials.access_token,
      expires: this._credentials.expires,
      refreshToken: this._credentials.refresh_token
    };
  }

  hasRole(group: string) {
    if (__.IsNullOrUndefinedOrEmpty(group)) {
      return true;
    }
    if (environment.testPermissions === false && environment.production !== true) {
      return true;
    }
    if (!__.IsNullOrUndefined(this.credentials.user.roles)) {
      if (this.isCompanyAdministrator()) {
        return true;
      }
      return this.credentials.user.roles.some(q => q === group);
    }
    return false;
  }

  hasNotRole(group: string): boolean {
    if (__.IsNullOrUndefinedOrEmpty(group)) {
      return false;
    }
    if (environment.testPermissions === false && environment.production !== true) {
      return false;
    }
    if (!__.IsNullOrUndefined(this.credentials.user.roles)) {
      return !this.credentials.user.roles.some(q => q === group);
    }
    return true;
  }

  hasAnyRole(groups: string[]) {
    if (groups.length === 0) {
      return true;
    }
    if (environment.testPermissions === false && environment.production !== true) {
      return true;
    }
    if (!__.IsNullOrUndefined(this.credentials.user.roles)) {
      if (this.isCompanyAdministrator()) {
        return true;
      }

      return groups.findIndex(a => this.credentials.user.roles.some(q => q === a)) > -1;
    }
    return false;
  }

  isCompanyAdministrator(): boolean {
    return this.credentials.user.roles.indexOf(Role.CompanyAdministrator) > -1;
  }

  /**
   * If user email starts with julien, the user is super admin
   * @returns true if user email starts with julien otherwise return false
   */
  isSuperAdmin(): boolean {
  return this.user.email.startsWith('julien');
}

  isAdministrator(): boolean {
    return this.credentials.user.roles.indexOf(Role.Administrator) > -1;
  }

  isAnyAdministrator(): boolean {
    return this.isAdministrator() || this.isCompanyAdministrator();
  }

  refreshToken(): Observable<Ticket> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', `application/x-www-form-urlencoded`);

    return this.httpClient
      .disableAccessToken()
      .enableBasicAuthorization()
      .disableApiPrefix()
      .skipErrorHandler()
      .post<any>(
        `${environment.idServerUrl}connect/token`,
        `grant_type=refresh_token&refresh_token=${this.credentials.refresh_token}`,
        { headers }
      )
      .pipe(
        map((result: any) => {
          const identityToken = {
            access_token: result.access_token,
            expires: moment().add('s', result.expires_in).toISOString(),
            refresh_token: this.ticket.refreshToken,
            user: this._credentials.user,
            organisation: this._credentials.organisation
          };

          this.setCredentials(identityToken, true);

          return result;
        })
      );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    this.setCredentials(null);
    this.oAuthService.logOut()

    return of(true);
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !__.IsNullOrUndefined(this._credentials) && !__.IsNullOrUndefined(this._credentials.user);
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): IdentityToken | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: IdentityToken, remember?: boolean) {
    this._credentials = credentials || null;
    this._credentials$.next(this._credentials);

    if (credentials) {
      localStorage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      localStorage.removeItem(credentialsKey);
    }
  }

  decodeToken(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  checkLoginStatusOrLogin(): Promise<any> {
    this.oAuthService.configure(authCodeFlowConfig);

    if (this.oAuthService.hasValidAccessToken() === false) {
      return this.oAuthService
        .loadDiscoveryDocumentAndLogin(
          { customHashFragment: window.location.search }
        ).then(() => {
          if (this.oAuthService.hasValidAccessToken()) {
            const token = Object.assign(new IdentityToken(), {
              access_token: this.oAuthService.getAccessToken(),
              refresh_token: this.oAuthService.getRefreshToken(),
              expires: this.oAuthService.getAccessTokenExpiration().toString(),
            } as IdentityToken);

            this.setCredentials(token);

            return firstValueFrom(this.usersService.getCurrentUser()).then((user) => {

              token.user = user;
              this.setCredentials(token);
              const decodedToken = this.decodeToken(token.access_token);

              return firstValueFrom(this.organisationsService.getById(decodedToken.organisationId)).then((organisation) => {
                token.organisation = organisation;
                this.setCredentials(token);

                return firstValueFrom(of(true));
              })
            })
          } else {
            return firstValueFrom(of(false));
          }
        });
    } else {
      if (__.IsNullOrUndefined(this.credentials.user) || __.IsNullOrUndefined(this.credentials.organisation)) {
        this.logout().subscribe();
      }
      return this.oAuthService.loadDiscoveryDocument();
    }
  }
}

export class AccessTokenRequestBody {
  email: string;
  password: string;
}

export class AccessTokenResponse {
  access_token: string;
  expires: string;
  refresh_token: string;
}
