<kb-dialog-header [title]="data.title " (emitCloseClick)="dialogRef.close(null)">
</kb-dialog-header>

<kb-dialog-content class="dialog-content"
  [class.override-max-height]="!IsNullOrUndefinedOrEmpty(data.contentMaxHeight)"
  [style.maxHeight]="!IsNullOrUndefinedOrEmpty(data.contentMaxHeight) ? data.contentMaxHeight : 'initial'">

  <ng-template kbDialogContentHost></ng-template>

  <ng-container [ngSwitch]="mode">
    <ng-container *ngSwitchCase="'Text'">
      <div class="text-content" [innerHTML]="data.text | safe:'html'"></div>
    </ng-container>
  </ng-container>

</kb-dialog-content>

<kb-dialog-footer>

  <div class="flex flex-row">
    <kb-buttons-default class="flex-initial" (onClick)="dialogRef.close(null)">{{'General.Cancel' | translate}}
    </kb-buttons-default>
    <ng-container *ngIf="mode === 'Text'">
      <kb-buttons-submit class="ml-1 flex-initial" (onClick)="dialogRef.close(true)">{{'General.Confirm' | translate}}
      </kb-buttons-submit>
    </ng-container>
  </div>
</kb-dialog-footer>