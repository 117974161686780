import { ChangeDetectionStrategy, Component, ContentChild } from '@angular/core';

import { BaseSelectFormFieldComponent } from '../base/base-form-field.component';
import { FormFieldsSelectRowDirective } from './row/form-fields-select-row.directive';
import { FormFieldsSelectTriggerDirective } from './trigger/form-fields-select-trigger.directive';

@Component({
  selector: 'jad-form-fields-select',
  templateUrl: './form-fields-select.component.html',
  styleUrls: ['./form-fields-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JadFormFieldsSelectComponent<T> extends BaseSelectFormFieldComponent<T> {
  @ContentChild(FormFieldsSelectRowDirective, { static: true }) row: FormFieldsSelectRowDirective;

  @ContentChild(FormFieldsSelectTriggerDirective, { static: true }) trigger: FormFieldsSelectTriggerDirective;
}
