<button (click)="isDisabled || isLoading ? null : onClick.emit()" matRipple [matRippleDisabled]="isDisabled || isLoading" [matTooltipDisabled]="tooltipDisabled"
    [matTooltip]="tooltipText" [style]="buttonStyle" [disabled]="isDisabled || isLoading"
    [ngClass]="{ 'disabled': isDisabled === true || isLoading === true }"
    class="hover:opacity-80 leading-normal group border border-solid border-gray-100 h-10 flex items-center rounded-md bg-white text-sm font-medium px-4 py-2">
    <!-- LOADER REVERSE -->
    <ng-container *ngIf="isLoading && order === 'icon-last'">
        <mat-spinner [diameter]="20"></mat-spinner>
    </ng-container>

    <!-- ICON -->
    <ng-container *ngIf="!IsNullOrUndefinedOrEmpty(icon)">
        <mat-icon [ngClass]="[sizeClass, iconClass]" [svgIcon]="icon"></mat-icon>
    </ng-container>

    <!-- CONTENT -->
    {{ displayText }}

    <div>
        <ng-content></ng-content>
    </div>

    <!-- LOADER -->
    <ng-container *ngIf="isLoading && order !== 'icon-last'">
        <mat-spinner class="ml-8" [diameter]="20"></mat-spinner>
    </ng-container>
</button>