import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { GenericMenuItem } from '../generic-menu/generic-menu-item.model';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { __ } from '@app/shared/functions/object.functions';

@Component({
  selector: 'kb-generic-actions',
  templateUrl: './generic-actions.component.html',
  styleUrls: ['./generic-actions.component.scss']
})
export class GenericActionsComponent extends BaseComponent implements OnInit {

  actions: GenericMenuItem[] = [];

  @Input() item: any;

  @Input() items: GenericMenuItem[] = [];

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onItemClicked: EventEmitter<GenericMenuItem> = new EventEmitter<GenericMenuItem>();

  constructor(
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.actions = this.items;
  }

  /**
   * The callback when a menu item was clicked. Executes up to three handling actions:
   * 1. Emits the clicked menu item
   * 2. Navigates to a provided route
   * 3. Opens a dialog using the provided open dialog method
   *
   * @memberof GenericMenuComponent
   */
  itemClick(menuItem: GenericMenuItem): void {
    if (!__.IsNullOrUndefined(menuItem.actionType)) {
      this.onItemClicked.emit(menuItem);
    }
    if (!__.IsNullOrUndefined(menuItem.routerLink)) {
      this.router.navigate([menuItem.routerLink]);
    }
    if (!__.IsNullOrUndefined(menuItem.execute)) {
      menuItem.execute.call(
        __.IsNullOrUndefined(menuItem.context) ? this : menuItem.context
      );
    }
  }

  checkDisabled(menuItem: GenericMenuItem) {
    if (typeof (menuItem.disabled) === typeof (true)) {
      return menuItem.disabled;
    }
    if (this.isFunction(menuItem.disabled)) {
      return menuItem.disabled.call(__.IsNullOrUndefined(menuItem.context) ? this : menuItem.context, this.item);
    }
    return true;
  }

  checkIsLoading(menuItem: GenericMenuItem) {
    if (typeof (menuItem.isLoading) === typeof (true)) {
      return menuItem.isLoading;
    }
    if (this.isFunction(menuItem.isLoading)) {
      return menuItem.isLoading.call(__.IsNullOrUndefined(menuItem.context) ? this : menuItem.context, this.item);
    }
    return false;
  }

  checkIsVisible(menuItem: GenericMenuItem) {
    if (typeof (menuItem.visible) === typeof (true)) {
      return menuItem.visible;
    }
    if (this.isFunction(menuItem.visible)) {
      return menuItem.visible.call(__.IsNullOrUndefined(menuItem.context) ? this : menuItem.context, this.item);
    }
    return false;
  }

  private isFunction(functionToCheck: any) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }
}
