import { Injectable } from '@angular/core';
import { __ } from '@app/shared/functions/object.functions';
import { environment } from '@env/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import enUS from '../../translations/en-US.json';
import deDE from '../../translations/de-DE.json';
import { Logger } from './logger.service';

const log = new Logger('I18nService');

const languageKey = 'language';

/** 
 * Pass-through function to mark a string for translation extraction. 
 * Running `npm translations:extract` will include the given string by using this. 
 * @param s The string to extract for translation. 
 * @return The same string. 
 */

export function extract(s: string) {
    return s;
}

export class Language {
    locale: string;
    name: string;
}

@Injectable()
export class I18nService {
    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    defaultLanguage: Language;

    supportedLanguages: Language[];

    // -----------------------------------------------------------------------------------------------------
    // @ PRIVATE INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    private _language: Language;

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(private translateService: TranslateService) {
        // Embed languages to avoid extra HTTP requests 
        translateService.setTranslation('en-US', enUS);
        translateService.setTranslation('de-DE', deDE);
        this.defaultLanguage = environment.defaultLanguage;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------

    /** 
     * Initializes i18n for the application. 
     * Loads language from local storage if present, or sets default language. 
     * @param defaultLanguage The default language to use. 
     * @param supportedLanguages The list of supported languages. 
     */

    init(supportedLanguages: Language[]) {
        this.supportedLanguages = supportedLanguages;
        this.language = undefined;

        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            localStorage.setItem(languageKey, event.lang);
        });
    }

    /** 
     * Sets the current language. 
     * Note: The current language is saved to the local storage. 
     * If no parameter is specified, the language is loaded from local storage (if present). 
     * @param language The IETF language code to set. 
     */

    set language(language: any) {
        language = language || localStorage.getItem(languageKey) || this.translateService.getBrowserCultureLang();
        let foundLanguage = environment.supportedLanguages.find(q => q.locale === language);

        // If no exact match is found, search without the region 
        if (language && __.IsNullOrUndefined(foundLanguage)) {
            language = language.split('-')[0];

            foundLanguage = environment.supportedLanguages.find(supportedLanguage => supportedLanguage.locale.startsWith(language));
        }

        // Fallback if language is not supported 
        if (__.IsNullOrUndefined(foundLanguage)) {
            foundLanguage = __.IsNullOrUndefined(this.defaultLanguage) ? null : this.defaultLanguage;
        }

        this._language = foundLanguage;

        this.translateService.use((this._language as Language).locale);
    }

    /** 
     * Gets the current language. 
     * @return The current language code. 
     */

    get language(): any {
        let language = '';

        if (__.IsNullOrUndefined(this._language)) {
            this.language = undefined;
            language = this._language.locale;
        } else {
            language = __.IsNullOrUndefined((this._language as any).locale) ? this._language : (this._language as any).locale;
        }

        return language;
    }
}