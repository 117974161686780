import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonsDefaultComponent } from './buttons-default.component';
import { SharedModule } from '../../../shared.module';

@NgModule({
  imports: [
    SharedModule,
    MatIconModule,
    MatRippleModule,
    MatTooltipModule,
    MatProgressSpinnerModule
  ],
  declarations: [ButtonsDefaultComponent],
  exports: [ButtonsDefaultComponent]
})
export class ButtonsDefaultModule { }
