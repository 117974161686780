import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { ButtonsMenuActionModule } from './action/buttons-menu-action.module';
import { ButtonsMenuComponent } from './buttons-menu.component';
import { ButtonsMenuLabelDirective } from './menu-label/buttons-menu-label.directive';
import { SharedModule } from '../../../shared.module';

@NgModule({
  imports: [
    SharedModule,
    MatMenuModule,
    ButtonsMenuActionModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    ButtonsMenuComponent,
    ButtonsMenuLabelDirective
  ],
  exports: [
    ButtonsMenuComponent,
    ButtonsMenuActionModule,
    ButtonsMenuLabelDirective
  ]
})
export class ButtonsMenuModule { }
