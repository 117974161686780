import { NgModule } from '@angular/core';
import { FocusedDirective } from './focused.directive';
import { SharedModule } from '../../../../shared.module';

@NgModule({
    imports: [SharedModule],
    declarations: [FocusedDirective],
    exports: [FocusedDirective]
})
export class FocusedModule { }
