import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { I18nService } from '@app/core/i18n.service';
import { Logger } from '@app/core/logger.service';
import {
  ApplicationUser,
  ApplicationUserCreateOptions,
  ApplicationUserUpdateOptions,
  ResetPasswordBody,
} from '@app/shared/models/classes/ApplicationUser';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Address } from '../models/classes/Address';
import { EntityService } from './entity.service';
import { Response } from './local/Response';
import { Channel } from '../models/classes/channels/Channel';

const log = new Logger('Users Service');

@Injectable()
export class UsersService extends EntityService<ApplicationUser, ApplicationUserCreateOptions, ApplicationUserUpdateOptions> {

  constructor(
    protected httpClient: HttpClient,
    protected i18nService: I18nService
  ) {
    super('users', 'User', httpClient, i18nService);
  }

  update(id: string, entity: ApplicationUserUpdateOptions): Observable<ApplicationUser> {
    return super.update(id, entity);
  }

  getCurrentUser(): Observable<ApplicationUser> {
    return this.httpClient.get<any>(`users/me`).pipe(
      map((result: any) => {
        return result.data;
      })
    );
  }

  resetPassword(userId: string, resetPasswordDto: ResetPasswordBody) {

    return this.httpClient.patch<any>(
      `users/${userId}/password`,
      resetPasswordDto
    ).pipe(
      map((result: Response<boolean>) => {
        return result.code.statusCode === 200;
      })
    );
  }

  sendPasswordResetEmail(email: string) {

    return this.httpClient
      .post<any>(
        `users/password-reset`, email
      ).pipe(
        map((result: Response<boolean>) => {
          return result.code.statusCode === 200;
        })
      );
  }

  confirmEmail(userId: string, token: string): Observable<ApplicationUser> {
    return this.httpClient
      .disableAccessToken()
      .post<any>(
        `users/${userId}/confirm-email`,
        { token }
      ).pipe(
        map((result: Response<ApplicationUser>) => {
          return result.data;
        })
      );
  }

  getAllAddresses(userId: string): Observable<Address[]> {
    return this.httpClient
      .get<Response<Address[]>>(`users/${userId}/addresses`).pipe(
        map((result: Response<Address[]>) => {
          return result.data;
        })
      );
  }

  getChannelsByUserId(userId: string): Observable<Channel[]> {
    return this.httpClient
      .get<Response<Channel[]>>(`users/${userId}/channels`).pipe(
        map((result: Response<Channel[]>) => {
          return result.data;
        })
      );
  }

  addAddress(address: Address, userId: string): Observable<Address> {
    return this.httpClient
      .disableAcceptedLanguage()
      .disableCountryCode()
      .post(`users/${userId}/addresses`, address)
      .pipe(
        map((result: Response<Address>) => {
          return result.data;
        })
      );
  }

  updateAddress(address: Address, userId: string, addressId: string): Observable<Address> {
    return this.httpClient
      .patch(`users/${userId}/addresses/${addressId}`, address)
      .pipe(
        map((result: Response<Address>) => {
          return result.data;
        })
      );
  }

  deleteAddress(addressId: string, isDefault: boolean, userId: string): Observable<boolean> {
    if (isDefault) {
      return of(false);
    }

    return this.httpClient
      .disableAcceptedLanguage()
      .disableCountryCode()
      .delete(`users/${userId}/addresses/${addressId}`)
      .pipe(
        map((result: Response<boolean>) => {
          return result.data;
        })
      );
  }

  getMentionableUsers(searchString: string): Observable<ApplicationUser[]> {
    return this.httpClient.get<any>(`users?filter=${searchString}&isMentionable=true`).pipe(
      map((result: any) => {
        return result.data;
      })
    );
  }

  changePassword(currentPassword: string, newPassword: string): Observable<boolean> {
    return this.httpClient
      .patch<Response<boolean>>(`users/me/password`, {
        currentPassword,
        password: newPassword
      })
      .pipe(
        map((result: Response<boolean>) => {
          return result.code.statusCode === 200;
        })
      );
  }

  changePermissions(userId: string, permissions: string[], useCaseIds: string[]): Observable<boolean> {
    return this.httpClient
      .put<Response<boolean>>(`users/${userId}/permissions`, { permissions, useCaseIds })
      .pipe(
        map((result: Response<boolean>) => {
          return result.data;
        })
      );
  }

  changeRoles(userId: string, userRole: string): Observable<boolean> {
    return this.httpClient
      .patch<Response<boolean>>(`users/${userId}/roles`, { role: userRole })
      .pipe(
        map((result: Response<boolean>) => {
          return result.code.statusCode === 200;
        })
      );
  }

  deleteManyByIds(ids: string[]): Observable<boolean> {
    const filter = ids.map(id => `id=${id}&`).join('');
    return this.httpClient.delete<Response<boolean>>(`users?${filter}`).pipe(
      map((result: Response<boolean>) => {
        return result.code.statusCode === 200;
      })
    );
  }
}
