export const environment = {
  production: false,
  testPermissions: true,
  partnerWebAppUrl: 'https://sendable-platform-webapp-qa.jad-sandbox.com/',
  adminWebAppUrl: 'https://sendable-admin-webapp-qa.jad-sandbox.com/',
  idServerUrl: 'https://sendable-api-platform-qa.jad-sandbox.com/',
  idpServerUrl: 'https://sendable-api-idp-qa.jad-sandbox.com/',
  idaServerUrl: 'https://sendable-api-admin-qa.jad-sandbox.com/',
  serverUrl: 'https://sendable-api-platform-qa.jad-sandbox.com/api/',
  serverUrlCDN: 'https://sendable-api-platform-qa.jad-sandbox.com/api/',
  adminUrl: 'https://sendable-api-admin-qa.jad-sandbox.com/api/',
  adminUrlCDN: 'https://sendable-api-admin-qa.jad-sandbox.com/api/',
  loginClientId: '96a7e7a8-cb9a-467d-8fd6-4e5b687f753d',
  defaultLanguage: { locale: 'de-DE', name: 'Deutsch' },
  environmentName: 'QA',
  supportedLanguages: [
    { locale: 'de-DE', name: 'Deutsch' },
    { locale: 'en-US', name: 'English' }
  ]
};
