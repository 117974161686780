import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { I18nService } from '@app/core/i18n.service';

import { Organisation } from '../models/classes/organisations/Organisation';
import { OrganisationCreateOptions } from '../models/classes/organisations/OrganisationCreateOptions';
import { OrganisationUpdateOptions } from '../models/classes/organisations/OrganisationUpdateOptions';
import { EntityService } from './entity.service';

@Injectable()
export class OrganisationsService extends EntityService<Organisation, OrganisationCreateOptions, OrganisationUpdateOptions> {
    constructor(protected httpClient: HttpClient, protected i18nService: I18nService) {
        super('organisations', 'organisations', httpClient, i18nService);
    }
}
