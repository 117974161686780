import { ComponentType } from '@angular/cdk/portal';
import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injector,
  ModuleWithProviders,
  OnInit,
  ViewChild,
  ɵcreateInjector as createInjector,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { __ } from '@app/shared/functions/object.functions';
import { IComponentFactoryResolvable } from '@app/shared/components/dialog/models/interfaces/IComponentFactoryResolvable';

import { BaseDialogComponent } from './models/base/base-dialog.component';
import { DialogContentHostDirective } from './dialog-content-host.directive';

export class DialogData<TModule extends IComponentFactoryResolvable, TComponent, TData> {
  getModule?: () => Promise<ModuleWithProviders<TModule>>;
  getComponent?: () => Promise<ComponentType<TComponent>>;
  componentData?: TData;
  title?: string;
  text?: string;
  contentMaxHeight?: string;
}

@Component({
  selector: 'kb-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent<TModule extends IComponentFactoryResolvable, TComponent, TData> extends BaseDialogComponent implements OnInit {

  mode: 'Component' | 'Text';

  @ViewChild(DialogContentHostDirective, { static: true }) contentDialogHost: DialogContentHostDirective;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData<TModule, TComponent, TData>,
    public dialogRef: MatDialogRef<DialogComponent<TModule, TComponent, TData>>,
    private injector: Injector
  ) {
    super();
  }

  ngOnInit() {
    if (!__.IsNullOrUndefined(this.data.getModule) && !__.IsNullOrUndefined(this.data.getComponent)) {
      this.mode = 'Component';

      const viewContainerRef = this.contentDialogHost.viewContainerRef;
      viewContainerRef.clear();

      this.data.getModule().then((module: ModuleWithProviders<TModule>) => {
        const injector = createInjector(module, this.injector);

        // TODO: check if 'module' is a Module
        const createdModule = injector.get(module) as IComponentFactoryResolvable;

        this.data.getComponent().then((component: ComponentType<TComponent>) => {
          const componentFactory = createdModule.getComponentFactoryResolver().resolveComponentFactory<TComponent>(component);
          const createdComponent = this.contentDialogHost.viewContainerRef.createComponent(componentFactory);

          if (!__.IsNullOrUndefined(this.data.componentData)) {
            Object.assign(createdComponent.instance, this.data.componentData);
          }
        });
      });
    } else if (!__.IsNullOrUndefinedOrEmpty(this.data.text) && !__.IsNullOrUndefinedOrEmpty(this.data.title)) {
      this.mode = 'Text';
    }
  }
}
