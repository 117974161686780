import { ApplicationUser } from '@app/shared/models/classes/ApplicationUser';
import { Organisation } from './organisations/Organisation';

export class IdentityToken {
  expires: string;
  access_token: string;
  refresh_token: string;
  user: ApplicationUser;
  organisation: Organisation;
}
