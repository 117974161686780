import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { ButtonsMenuActionComponent } from './buttons-menu-action.component';
import { SharedModule } from '../../../../shared.module';

@NgModule({
  imports: [
    SharedModule,
    MatIconModule
  ],
  declarations: [ButtonsMenuActionComponent],
  exports: [ButtonsMenuActionComponent]
})
export class ButtonsMenuActionModule { }
